define("discourse/plugins/stemaway-project-generation/discourse/connectors/timeline-controls-before/ai-mentor", ["exports", "@ember/service", "@ember/component", "@ember/runloop", "discourse/lib/ajax", "@ember/object"], function (_exports, _service, _component, _runloop, _ajax, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    evaluationService: (0, _service.inject)('ai-mentor-service'),
    mcqService: (0, _service.inject)('evaluation-service'),
    currentUser: (0, _service.inject)(),
    chatVisible: false,
    messages: [],
    userInput: '',
    chatCollapsed: false,
    systemEvaluation: null,
    showEvaluationPopup: false,
    stemawayUser: null,
    isFinalEvaluation: false,
    isLoadingResponse: false,
    currentQuestionIndex: 0,
    userFeedback: [],
    followUpInProgress: false,
    followUpCount: 0,
    currentFollowUpQuestion: null,
    questions: null,
    evaluationParameters: {
      technicalProficiency: 0,
      problemSolving: 0,
      innovation: 0,
      adaptability: 0,
      initiative: 0,
      communication: 0
    },
    tabOutCount: 0,
    maxTabOuts: 4,
    userPoints: null,
    isButtonDisabled: true,
    init() {
      this._super(...arguments);
      this.evaluationService.checkIfTopicInCodeAlongCategory().then(() => {
        this.topicId = this.get('router.currentRoute.parent.params.id');
        if (!this.get('evaluationService.displayCodeAlongButton')) {
          return;
        }
        this.setProperties({
          chatVisible: false,
          chatCollapsed: true
        });
        this.updateUserPoints();
        if (this.get('currentUser')) {
          (0, _ajax.ajax)('/users/stemaway.json').then(response => {
            this.set('stemawayUser', response.user);
          });
        }
        this.checkOngoingEvaluation();
        window.addEventListener('beforeunload', this.beforeUnloadHandler.bind(this));
      }).catch(error => {
        console.error('Error checking code along category:', error);
      });
    },
    isEvaluationEnabled: (0, _object.computed)('mcqService.parsedScore', 'siteSettings.evaluation_pass_score', function () {
      return this.mcqService.parsedScore >= this.siteSettings.evaluation_pass_score;
    }),
    sufficientTacks: (0, _object.computed)('userPoints', 'siteSettings.aivia_eval_tacks_cost', function () {
      return this.userPoints >= this.siteSettings.aivia_eval_tacks_cost;
    }),
    isButtonDisabled: (0, _object.computed)('isEvaluationEnabled', 'sufficientTacks', function () {
      return !(this.isEvaluationEnabled && this.sufficientTacks);
    }),
    willDestroyElement() {
      window.removeEventListener('beforeunload', this.beforeUnloadHandler.bind(this));
      this.removeFocusAlert();
      this._super(...arguments);
    },
    checkOngoingEvaluation() {
      const ongoingTopicId = localStorage.getItem('ongoingEvaluationTopicId');
      if (ongoingTopicId) {
        if (ongoingTopicId === this.topicId) {
          this.get('messages').pushObject({
            html: "Evaluation was interrupted",
            sender: 'system',
            isHtml: true
          });
          this.disableInput = false;
        } else {
          this.get('messages').pushObject({
            html: "Another evaluation in progress",
            sender: 'system',
            isHtml: true
          });
          this.disableInput = true;
        }
      } else {
        this.checkGreeting();
        this.disableInput = false;
      }
    },
    updateUserPoints() {
      const username = this.get('currentUser.username');
      return (0, _ajax.ajax)("/stemaway-project-generation/fetch-user-points", {
        type: "GET",
        data: {
          username
        }
      }).then(response => {
        this.set('userPoints', response.points);
      });
    },
    beforeUnloadHandler(event) {
      if (this.isEvaluateMode) {
        const message = "You have an ongoing evaluation. Are you sure you want to leave?";
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    },
    resetEvaluationState() {
      this.setProperties({
        isEvaluateMode: false,
        tabOutCount: 0,
        currentQuestionIndex: 0,
        userFeedback: [],
        questions: null,
        followUpInProgress: false,
        currentFollowUpQuestion: null,
        evaluationParameters: {
          technicalProficiency: 0,
          problemSolving: 0,
          innovation: 0,
          adaptability: 0,
          initiative: 0,
          communication: 0
        },
        disableInput: false,
        evalbuttonsRendered: false
      });
      localStorage.removeItem('ongoingEvaluationTopicId');
      this.resetChat();
    },
    resetChat() {
      this.setProperties({
        messages: [],
        userInput: '',
        isFinalEvaluation: false,
        currentQuestionIndex: 0,
        userFeedback: []
      });
      let message = this.generateGreetingMessage();
      this.get('messages').pushObject({
        html: message,
        sender: 'ai',
        isHtml: true
      });
      this.scrollToBottom();
    },
    generateGreetingMessage() {
      return this.siteSettings.aivia_greeting_message;
    },
    checkGreeting() {
      const chatMessagesContainer = document.querySelector('.chat-messages-topics-mcq');
      if (chatMessagesContainer) {
        chatMessagesContainer.lastElementChild?.scrollIntoView({
          behavior: 'smooth'
        });
      }
      const ongoingTopicId = localStorage.getItem('ongoingEvaluationTopicId');
      if (this.evaluationService.firstGreeting) {
        if (!ongoingTopicId || ongoingTopicId && ongoingTopicId === this.topicId) {
          let message = this.generateGreetingMessage();
          this.get('messages').pushObject({
            html: message,
            sender: 'ai',
            isHtml: true
          });
          this.evaluationService.setFirstGreeting(false);
        }
      }
    },
    scrollToBottom() {
      (0, _runloop.scheduleOnce)('afterRender', this, function () {
        const chatMessagesContainer = document.querySelector('.chat-messages-topics-mcq');
        if (chatMessagesContainer) {
          chatMessagesContainer.lastElementChild.scrollIntoView({
            behavior: 'smooth'
          });
        }
      });
    },
    makeAPIRequest(prompt, model) {
      let temperature = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      if (!this.isEvaluationEnabled) {
        return;
      }
      this.set('isLoadingResponse', true);
      const encodedPrompt = btoa(unescape(encodeURIComponent(prompt)));
      const encodedModel = btoa(model);
      const firstName = this.get('currentUser.name').split(' ')[0];
      return (0, _ajax.ajax)("/stemaway-project-generation/make-api-request", {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          prompt: encodedPrompt,
          model: encodedModel,
          mcq: false,
          first_name: firstName,
          temperature: temperature
        })
      }).finally(() => {
        // this.set('isLoadingResponse', false);
      });
    },
    addFocusAlert() {
      this._handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          this.incrementProperty('tabOutCount');
          const remainingTabOuts = this.maxTabOuts - this.tabOutCount;
          if (this.tabOutCount < this.maxTabOuts) {
            const plural = remainingTabOuts === 1 ? 'time' : 'times';
            this.get('messages').pushObject({
              html: `You have switched tabs ${this.tabOutCount} times. If you switch tabs ${remainingTabOuts} more ${plural}, the evaluation will be canceled.`,
              sender: 'system',
              isHtml: true
            });
            this.scrollToBottom();
          } else {
            this.cancelEvaluation();
          }
        }
      };
      document.addEventListener('visibilitychange', this._handleVisibilityChange);
    },
    removeFocusAlert() {
      if (this._handleVisibilityChange) {
        document.removeEventListener('visibilitychange', this._handleVisibilityChange);
        this._handleVisibilityChange = null;
      }
    },
    cancelEvaluation() {
      this.resetEvaluationState();
      this.endEvaluation();
      this.get('messages').pushObject({
        html: "<strong><em>The evaluation has been canceled because you switched tabs too many times.</em></strong>",
        sender: 'ai',
        isHtml: true
      });
      this.removeFocusAlert();
      this.scrollToBottom();
    },
    startEvaluation() {
      this.set('tabOutCount', 0);
      this.addOverlay();
      this.preventCopyPaste();
      const chatWindow = document.querySelector('.ai-chat-interface-topics-mcq');
      if (chatWindow) {
        chatWindow.classList.add('disable-chat-selection');
        this.disableTextSelection();
      }
      this.addFocusAlert();
      window.addEventListener('beforeunload', this.beforeUnloadHandler.bind(this));
      localStorage.setItem('ongoingEvaluationTopicId', this.topicId);
    },
    endEvaluation() {
      const overlay = document.querySelector('.disable-overlay');
      if (overlay) {
        overlay.remove();
      }
      const chatWindow = document.querySelector('.ai-chat-interface-topics-mcq');
      if (chatWindow) {
        chatWindow.classList.remove('disable-chat-selection');
      }
      this.enableCopyPaste();
      this.removeFocusAlert();
      window.removeEventListener('beforeunload', this.beforeUnloadHandler.bind(this));
      localStorage.removeItem('ongoingEvaluationTopicId');
      this.set('isEvaluateMode', false);
    },
    addOverlay() {
      let overlay = document.querySelector('.disable-overlay');
      if (!overlay) {
        overlay = document.createElement('div');
        overlay.classList.add('disable-overlay');
        document.body.appendChild(overlay);
      }
    },
    preventCopyPaste() {
      document.addEventListener('copy', this.preventDefaultAction);
      document.addEventListener('cut', this.preventDefaultAction);
      document.addEventListener('paste', this.preventDefaultAction);
      document.addEventListener('contextmenu', this.preventDefaultAction);
    },
    enableCopyPaste() {
      document.removeEventListener('copy', this.preventDefaultAction);
      document.removeEventListener('cut', this.preventDefaultAction);
      document.removeEventListener('paste', this.preventDefaultAction);
      document.removeEventListener('contextmenu', this.preventDefaultAction);
    },
    preventDefaultAction(e) {
      e.preventDefault();
    },
    processEvaluation() {
      this.set('isLoadingResponse', true);
      this.startEvaluation();
      (0, _ajax.ajax)(`/stemaway-project-generation/fetch-topic-content/${this.topicId}`).then(response => {
        if (response.error) {
          throw new Error(response.error);
        }
        let topicContent = response.content;
        const questionsPromptTemplate = this.siteSettings.aivia_evaluation_questions_prompt;
        const questionsPrompt = questionsPromptTemplate.replace('{{topicContent}}', topicContent);
        return this.makeAPIRequest(questionsPrompt, 'main', 0.5);
      }).then(response => {
        if (response) {
          const content = decodeURIComponent(escape(atob(response)));
          // console.log('content:', content);
          const questions = this.parseQuestions(content);
          this.evaluateUserOnQuestions(questions);
        } else {
          this.get('messages').pushObject({
            text: "We encountered an issue. Please try again later.",
            sender: 'ai'
          });
        }
        this.scrollToBottom();
      }).catch(error => {
        console.error('Error during evaluation process:', error);
        this.get('messages').pushObject({
          text: `Error: ${error.message}`,
          sender: 'system'
        });
      }).finally(() => {
        // this.set('isLoadingResponse', false);
      });
    },
    parseQuestions(content) {
      const questionBlocks = content.split(/\n?\d+\.\s+/).filter(block => block.trim());
      return questionBlocks.map((block, index) => {
        const questionText = block.trim();
        const isInstruction = questionText.toLowerCase().includes("here are") || questionText.toLowerCase().includes("instructions");
        if (isInstruction) {
          return null;
        }
        return {
          index: index + 1,
          question: questionText,
          options: []
        };
      }).filter(Boolean);
    },
    evaluateUserOnQuestions(questions) {
      this.set('questions', questions);
      this.set('currentQuestionIndex', 0);
      this.set('followUpInProgress', false);
      this.set('currentFollowUpQuestion', null);
      this.askNextQuestion();
    },
    askNextQuestion() {
      this.set('isLoadingResponse', true);
      const question = this.questions[this.currentQuestionIndex];
      if (this.currentQuestionIndex < this.questions.length) {
        const feedbackMessage = this.generateQuestionText(question);
        this.get('messages').pushObject({
          html: feedbackMessage,
          sender: 'ai',
          isHtml: true
        });
        this.scrollToBottom();
        setTimeout(() => {
          this.set('isLoadingResponse', false);
          this.set('userInput', '');
        }, 500);
      } else {
        this.provideConsolidatedFeedback();
      }
    },
    recordUserResponse(response) {
      const currentQuestion = this.questions[this.currentQuestionIndex];
      const topicContent = this.get('questions').map(q => q.question).join("\n");
      const questionText = this.get('followUpInProgress') ? `Follow-up to question "${currentQuestion.question}": ${this.get('currentFollowUpQuestion')}` : currentQuestion.question;
      const firstName = this.get('currentUser.name').split(' ')[0];
      const personalizedPrompt = `The student's name is ${firstName}. The response is "${response}".`;
      const evaluationPromptTemplate = this.siteSettings.aivia_evaluation_prompt;
      this.set('isLoadingResponse', true);
      const evaluationPrompt = evaluationPromptTemplate.replace('{{topicContent}}', topicContent).replace('{{questionText}}', questionText).replace('{{personalizedPrompt}}', personalizedPrompt);
      this.makeAPIRequest(evaluationPrompt, 'main').then(feedbackResponse => {
        const feedbackData = this.processFeedback(decodeURIComponent(escape(atob(feedbackResponse))));
        this.userFeedback.push({
          question: questionText,
          userResponse: response,
          feedback: feedbackData.feedback,
          scores: feedbackData.scores
        });
        this.updateEvaluationParameters(feedbackData.scores);
        this.get('messages').pushObject({
          html: feedbackData.feedback,
          sender: 'ai',
          isHtml: true
        });
        this.scrollToBottom();
        setTimeout(() => {
          if (this.get('followUpInProgress') === 2) {
            this.set('followUpInProgress', false);
            this.set('currentFollowUpQuestion', null);
            this.incrementProperty('currentQuestionIndex');
            this.askNextQuestion();
          } else if (this.get('followUpInProgress') === 1) {
            const shouldAskSecondFollowUp = Math.random() < 0.75;
            if (shouldAskSecondFollowUp) {
              this.set('followUpInProgress', 2);
              this.askSecondFollowUpQuestion(currentQuestion, response);
            } else {
              this.set('followUpInProgress', false);
              this.set('currentFollowUpQuestion', null);
              this.incrementProperty('currentQuestionIndex');
              this.askNextQuestion();
            }
          } else {
            this.set('followUpInProgress', 1);
            this.askFirstFollowUpQuestion(currentQuestion, response);
          }
        }, Math.random() * 500 + 1500);
      }).catch(error => {
        console.error('Error during feedback process:', error);
        this.get('messages').pushObject({
          text: `Error: ${error.message}`,
          sender: 'system'
        });
        this.incrementProperty('currentQuestionIndex');
        this.askNextQuestion();
      }).finally(() => {
        // this.set('isLoadingResponse', false);
      });
    },
    askFirstFollowUpQuestion(originalQuestion, userResponse) {
      const firstName = this.get('currentUser.name').split(' ')[0];
      const personalizedPrompt = `The student's name is ${firstName}. The original question is "${originalQuestion.question}". The student's response is "${userResponse}".`;
      this.set('isLoadingResponse', true);
      const followUpPromptTemplate = this.siteSettings.aivia_first_followup_question_prompt;
      const followUpPrompt = followUpPromptTemplate.replace('{{personalizedPrompt}}', personalizedPrompt);
      this.makeAPIRequest(followUpPrompt, 'main').then(followUpResponse => {
        const followUpQuestion = this.extractFollowUpQuestion(decodeURIComponent(escape(atob(followUpResponse))));
        // console.log(followUpQuestion);

        if (!followUpQuestion || followUpQuestion.trim() === "") {
          console.error("Received an empty follow-up question from the API. Response:", followUpResponse);
          this.get('messages').pushObject({
            html: "<p><strong>Error:</strong> The follow-up question could not be generated. Please try again.</p>",
            sender: 'system',
            isHtml: true
          });
        } else {
          const followUpMessage = `<p><strong>Follow-up:</strong> ${followUpQuestion}</p>`;
          this.get('messages').pushObject({
            html: followUpMessage,
            sender: 'ai',
            isHtml: true
          });
        }
        this.scrollToBottom();
        this.set('currentFollowUpQuestion', followUpQuestion);
        this.waitForUserResponse().then(followUpUserResponse => {
          this.set('isLoadingResponse', true);
          setTimeout(() => {
            this.set('isLoadingResponse', false);
            this.recordUserResponse(followUpUserResponse);
          }, Math.random() * 500 + 1500);
        });
      }).catch(error => {
        console.error('Error generating follow-up question:', error);
        this.get('messages').pushObject({
          text: `Error: ${error.message}`,
          sender: 'system'
        });
        this.incrementProperty('currentQuestionIndex');
        this.askNextQuestion();
      }).finally(() => {
        this.set('isLoadingResponse', false);
      });
    },
    askSecondFollowUpQuestion(originalQuestion, userResponse) {
      const firstName = this.get('currentUser.name').split(' ')[0];
      const personalizedPrompt = `The student's name is ${firstName}. The original question is "${originalQuestion.question}". The student's response to the follow-up is "${userResponse}".`;
      this.set('isLoadingResponse', true);
      const secondFollowUpPromptTemplate = this.siteSettings.aivia_second_followup_question_prompt;
      const secondFollowUpPrompt = secondFollowUpPromptTemplate.replace('{{personalizedPrompt}}', personalizedPrompt);
      this.makeAPIRequest(secondFollowUpPrompt, 'main').then(followUpResponse => {
        let decodedResponse;
        try {
          decodedResponse = decodeURIComponent(escape(atob(followUpResponse)));
        } catch (error) {
          console.error("Error decoding follow-up question:", error);
          this.get('messages').pushObject({
            html: "<p><strong>Error:</strong> Unable to decode the follow-up question. Please try again.</p>",
            sender: 'system',
            isHtml: true
          });
          return;
        }
        if (!decodedResponse || decodedResponse.trim() === "") {
          console.error("Received an empty follow-up question from the API. Response:", followUpResponse);
          this.get('messages').pushObject({
            html: "<p><strong>Error:</strong> The second follow-up question could not be generated. Please try again.</p>",
            sender: 'system',
            isHtml: true
          });
        } else {
          const followUpMessage = `<p><strong>Second Follow-up:</strong> ${decodedResponse}</p>`;
          this.get('messages').pushObject({
            html: followUpMessage,
            sender: 'ai',
            isHtml: true
          });
        }
        this.scrollToBottom();
        this.set('currentFollowUpQuestion', decodedResponse);
        this.waitForUserResponse().then(followUpUserResponse => {
          this.set('isLoadingResponse', true);
          setTimeout(() => {
            this.set('isLoadingResponse', false);
            this.recordUserResponse(followUpUserResponse);
          }, Math.random() * 500 + 1500);
        });
      }).catch(error => {
        console.error('Error generating second follow-up question:', error);
        this.incrementProperty('currentQuestionIndex');
        this.askNextQuestion();
      }).finally(() => {
        this.set('isLoadingResponse', false);
      });
    },
    extractFollowUpQuestion(responseText) {
      const match = responseText.match(/(?:\b(?:\w+\s*){1,10}:\s*)?("?.+?\?")/);
      return match ? match[1] : responseText.split('.').pop().trim();
    },
    waitForUserResponse() {
      return new Promise(resolve => {
        const captureResponse = event => {
          const response = event.target.value.trim();
          if (response) {
            resolve(response);
            document.querySelector('textarea').removeEventListener('keydown', captureResponse);
          }
        };
        document.querySelector('textarea').addEventListener('keydown', event => {
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            captureResponse(event);
          }
        });
      });
    },
    generateQuestionText(question) {
      return `
      <h3>Question ${question.index}</h3>
      <p>${question.question}</p>
    `;
    },
    processFeedback(feedbackText) {
      // console.log('processing feedback:', feedbackText);

      const feedbackMatch = feedbackText.match(/### Feedback\s*(.*?)\s*### Evaluation Parameters/);
      const feedback = feedbackMatch ? feedbackMatch[1].trim() : feedbackText.split('### Evaluation Parameters')[0].replace('### Feedback', '').trim();
      const scores = {
        technicalProficiency: this.extractScore(feedbackText, 'Technical Proficiency'),
        problemSolving: this.extractScore(feedbackText, 'Problem Solving'),
        innovation: this.extractScore(feedbackText, 'Innovation'),
        adaptability: this.extractScore(feedbackText, 'Adaptability'),
        initiative: this.extractScore(feedbackText, 'Initiative'),
        communication: this.extractScore(feedbackText, 'Communication')
      };
      return {
        feedback: feedback,
        scores: scores
      };
    },
    extractScore(feedbackText, parameter) {
      const regex = new RegExp(`${parameter.replace(/\s/g, '\\s*')}:\\s*(\\d+)\\/5`, 'i');
      const match = regex.exec(feedbackText);
      return match ? parseInt(match[1], 10) : null;
    },
    updateEvaluationParameters(scores) {
      this.set('evaluationParameters.technicalProficiency', scores.technicalProficiency);
      this.set('evaluationParameters.problemSolving', scores.problemSolving);
      this.set('evaluationParameters.innovation', scores.innovation);
      this.set('evaluationParameters.adaptability', scores.adaptability);
      this.set('evaluationParameters.initiative', scores.initiative);
      this.set('evaluationParameters.communication', scores.communication);

      // console.log('updated evaluation parameters:', this.evaluationParameters);
    },
    provideConsolidatedFeedback() {
      // console.log(this.userFeedback);

      const totalScores = {
        technicalProficiency: 0,
        problemSolving: 0,
        innovation: 0,
        adaptability: 0,
        initiative: 0,
        communication: 0
      };
      const feedbackCount = this.userFeedback.length;
      this.userFeedback.forEach(feedback => {
        totalScores.technicalProficiency += feedback.scores.technicalProficiency || 0;
        totalScores.problemSolving += feedback.scores.problemSolving || 0;
        totalScores.innovation += feedback.scores.innovation || 0;
        totalScores.adaptability += feedback.scores.adaptability || 0;
        totalScores.initiative += feedback.scores.initiative || 0;
        totalScores.communication += feedback.scores.communication || 0;
      });
      const averageScores = {
        technicalProficiency: Math.round(totalScores.technicalProficiency / feedbackCount),
        problemSolving: Math.round(totalScores.problemSolving / feedbackCount),
        innovation: Math.round(totalScores.innovation / feedbackCount),
        adaptability: Math.round(totalScores.adaptability / feedbackCount),
        initiative: Math.round(totalScores.initiative / feedbackCount),
        communication: Math.round(totalScores.communication / feedbackCount)
      };

      // console.log('average scores:', averageScores);

      const collectedFeedback = this.userFeedback.map(f => f.feedback).join('\n\n');
      const finalSummaryPromptTemplate = this.siteSettings.aivia_final_summary_prompt;
      const finalSummaryPrompt = finalSummaryPromptTemplate.replace('{{collectedFeedback}}', collectedFeedback);
      this.makeAPIRequest(finalSummaryPrompt, 'main').then(finalSummaryResponse => {
        const finalSummary = decodeURIComponent(escape(atob(finalSummaryResponse)));
        const formattedSummary = finalSummary.replace(/(\d+)\.\s+/g, '<br><strong>$1.</strong> ').replace(/(\.\s+)/g, '$1<br>').trim();
        let feedbackHtml = `
          <h4>Final Summary:</h4>
          <p>${formattedSummary}</p>
          <h4>Final Scores:</h4>
          <p>Technical Proficiency: ${this.mapScoreToExpertise(averageScores.technicalProficiency)}<br>
          Problem Solving: ${this.mapScoreToExpertise(averageScores.problemSolving)}<br>
          Innovation: ${this.mapScoreToExpertise(averageScores.innovation)}<br>
          Adaptability: ${this.mapScoreToExpertise(averageScores.adaptability)}<br>
          Initiative: ${this.mapScoreToExpertise(averageScores.initiative)}<br>
          Communication: ${this.mapScoreToExpertise(averageScores.communication)}</p>
        `;
        this.get('messages').pushObject({
          html: feedbackHtml,
          sender: 'ai',
          isHtml: true
        });
        this.sendNotification(formattedSummary);
        (0, _ajax.ajax)('/stemaway-project-generation/save-evaluation', {
          type: 'POST',
          data: {
            technical_proficiency: averageScores.technicalProficiency,
            problem_solving: averageScores.problemSolving,
            innovation: averageScores.innovation,
            adaptability: averageScores.adaptability,
            initiative: averageScores.initiative,
            communication: averageScores.communication,
            feedback: finalSummary.trim(),
            topic_id: this.topicId
          }
        }).then(() => {
          // console.log('evaluation data saved');

          this.setProperties({
            userFeedback: [],
            currentQuestionIndex: 0,
            followUpInProgress: false,
            currentFollowUpQuestion: null,
            questions: null,
            evaluationParameters: {
              technicalProficiency: 0,
              problemSolving: 0,
              innovation: 0,
              adaptability: 0,
              initiative: 0,
              communication: 0
            }
          });
        }).catch(error => {
          console.error('Error saving final evaluation data:', error);
        });
        this.scrollToBottom();
        this.endEvaluation();
      }).catch(error => {
        console.error('Error generating final summary:', error);
        this.get('messages').pushObject({
          text: `Error: ${error.message}`,
          sender: 'system'
        });
      });
    },
    sendNotification(finalSummary) {
      const currentUser = this.get('currentUser');
      const dashboardLink = `/u/${currentUser.username}/analytics`;
      (0, _ajax.ajax)('/stemaway-project-generation/send-evaluation-notification', {
        type: 'POST',
        data: {
          final_summary: finalSummary,
          dashboard_link: dashboardLink
        }
      }).then(() => {
        // console.log('notification sent eval');
      }).catch(error => {
        console.error('Error sending notification:', error);
      });
    },
    mapScoreToExpertise(score) {
      const expertiseMapping = {
        1: 'Novice',
        2: 'Developing',
        3: 'Proficient',
        4: 'Advanced',
        5: 'Expert'
      };
      return expertiseMapping[score] || 'Unknown';
    },
    disableTextSelection() {
      const chatWindow = document.querySelector('.ai-chat-interface-topics-mcq');
      if (chatWindow) {
        chatWindow.addEventListener('selectstart', e => e.preventDefault());
      }
    },
    enableTextSelection() {
      const chatWindow = document.querySelector('.ai-chat-interface-topics-mcq');
      if (chatWindow) {
        chatWindow.removeEventListener('selectstart', e => e.preventDefault());
      }
    },
    actions: {
      startChat() {
        if (!this.isEvaluationEnabled) {
          return;
        }
        this.checkGreeting();
        this.set('chatVisible', true);
        this.set('chatCollapsed', false);
        localStorage.setItem('chatVisible', this.get('chatVisible'));
        this.scrollToBottom();
      },
      toggleChat() {
        this.checkGreeting();
        this.toggleProperty('chatVisible');
        localStorage.setItem('chatVisible', this.get('chatVisible'));
        const chatWindow = document.querySelector('.ai-chat-interface-topics-mcq');
        if (this.chatVisible) {
          this.scrollToBottom();
          if (chatWindow) {
            chatWindow.classList.add('disable-chat-selection');
            this.disableTextSelection();
          }
        } else {
          if (chatWindow) {
            chatWindow.classList.add('disable-chat-selection');
            this.disableTextSelection();
          }
        }
      },
      toggleCollapseChat() {
        this.checkGreeting();
        this.toggleProperty('chatCollapsed');
        localStorage.setItem('chatCollapsed', this.get('chatCollapsed'));
        const chatWindow = document.querySelector('.ai-chat-interface-topics-mcq');
        if (!this.chatCollapsed) {
          this.scrollToBottom();
          if (chatWindow) {
            chatWindow.classList.add('disable-chat-selection');
          }
        } else {
          if (chatWindow) {
            chatWindow.classList.add('disable-chat-selection');
          }
        }
      },
      sendMessage() {
        if (this.disableInput) return;
        let message = this.get('userInput').trim();
        if (!message) {
          return;
        }
        this.get('messages').pushObject({
          text: message,
          sender: 'user'
        });
        this.scrollToBottom();
        this.set('userInput', '');
        if (this.isEvaluateMode && this.questions) {
          const ongoingTopicId = localStorage.getItem('ongoingEvaluationTopicId');
          if (!ongoingTopicId || ongoingTopicId !== this.topicId) {
            this.get('messages').pushObject({
              html: "<strong><em>The topic has changed or is no longer valid. The evaluation has been reset.</em></strong>",
              sender: 'system',
              isHtml: true
            });
            this.endEvaluation();
            this.resetEvaluationState();
            return;
          }
          this.recordUserResponse(message);
        } else if (message === "1") {
          if (!this.evalbuttonsRendered) {
            const requiredTacks = this.siteSettings.aivia_eval_tacks_cost;
            this.set('isEvaluateMode', true);
            this.set('evalbuttonsRendered', true);
            this.get('messages').pushObject({
              html: `
              <p>You will be asked a series of questions designed to evaluate your understanding of the material.</p>
              <p><strong>Important:</strong> Please do not refresh the page during the evaluation process.</p>
              <p><strong>Note:</strong> Stay on this page and complete the evaluation in one sitting for the best experience.</p>
              <p><strong>Required Tacks: <span style="color: red;">${requiredTacks}</span></strong></p>
              <p>
                <button class="start-eval-btn-ai">Start</button>
                <button class="exit-eval-btn-ai">Exit</button>
              </p>
            `,
              sender: 'ai',
              isHtml: true
            });
            this.scrollToBottom();
            (0, _runloop.scheduleOnce)('afterRender', this, function () {
              const startButton = document.querySelector('.start-eval-btn-ai');
              const exitButton = document.querySelector('.exit-eval-btn-ai');
              const requiredTacks = this.siteSettings.aivia_eval_tacks_cost;
              if (startButton) {
                startButton.addEventListener('click', async () => {
                  await this.updateUserPoints();
                  if (this.userPoints < requiredTacks) {
                    alert('Insufficient tacks');
                    return;
                  }
                  this.processEvaluation();
                  startButton.remove();
                  exitButton.remove();
                  this.mcqService.subtractPointsForEval(`AIVIA Evaluation (Topic - ${this.topicId})`, requiredTacks);
                });
              }
              if (exitButton) {
                exitButton.addEventListener('click', () => {
                  this.resetEvaluationState();
                  this.set('evalbuttonsRendered', false);
                });
              }
            });
          }
        } else {
          this.get('messages').pushObject({
            text: "Please type '1' to start the evaluation process.",
            sender: 'system'
          });
        }
      },
      handleTextareaKeyDown(event) {
        if (event.key === 'Enter' && !event.shiftKey && !this.disableInput) {
          event.preventDefault();
          this.send('sendMessage');
        }
      },
      toggleEvaluationPopup() {
        this.toggleProperty('showEvaluationPopup');
      },
      updateUserInput(value) {
        this.set('userInput', value);
      },
      handleResetAction() {
        this.resetEvaluationState.bind(this)();
      }
    }
  });
});